<template lang="pug">
  div
    v-row
      v-col(cols="12" v-if="newProjectCode")
        v-alert(
          type="success"
          dark
        )
          span Project Code {{ newProjectCode }} has been successfully added!
      v-col(cols="12")
        v-text-field(
          label="Description"
          v-model="description"
          :error-messages="projectCodeStorePostErrors.description"
        )
      v-col(cols="12")
        v-textarea(
          label="Notes"
          v-model="notes"
          :error-messages="projectCodeStorePostErrors.notes"
        )
      v-col(cols="12")
        v-btn(
          block
          color="success"
          dark
          @click="store"
          :loading="projectCodeStorePosting"
        )
          span Submit
</template>
<script>
import projectCodeRepository from '@/repositories/project-code.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [projectCodeStoreVars, projectCodeStoreVarNames] = requestVars({ identifier: 'project-code-store', request: 'post', hasData: false })

const projectCodeStoreHandler = new VueRequestHandler(null, projectCodeStoreVarNames)

const inputVars = () => ({
  description: null,
  notes: null,
})

export default {
  name: 'CreateProjectCode',
  data () {
    return {
      ...inputVars(),
      ...projectCodeStoreVars,
      newProjectCode: null,
    }
  },
  methods: {
    store () {
      const handler = projectCodeStoreHandler
      const repository = projectCodeRepository.store
      const data = { description: this.description, notes: this.notes }
      handler.setVue(this)
      handler.execute(repository, [data], ({ projectCode }) => {
        this.newProjectCode = projectCode
        this.reset()
      })
    },
    reset () {
      const defaults = inputVars()
      Object.keys(defaults).forEach(key => {
        this[key] = defaults[key]
      })
    },
  },
}
</script>